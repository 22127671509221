'use client';

export const types = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_FROM_BOSS: 'LOGIN_FROM_BOSS',

  LOGOUT_USER: 'LOGOUT_USER',
  SAVE_PIN: 'SAVE_PIN',
  DELETE_PIN: 'DELETE_PIN',

  GET_USER_ID_REQUEST: 'GET_USER_ID_REQUEST',
  GET_USER_ID_SUCCESS: 'GET_USER_ID_SUCCESS',

  SET_BACK_OFFLINE: 'SET_BACK_OFFLINE',
  SET_BACK_ONLINE: 'SET_BACK_ONLINE',

  GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',

  GET_CAPTCHA_REQUEST: 'GET_CAPTCHA_REQUEST',
  GET_CAPTCHA_FAILURE: 'GET_CAPTCHA_FAILURE',

  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE: 'CREATE_ACCOUNT_FAILURE',

  CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',

  GET_USER_EMAIL_REQUEST: 'GET_USER_EMAIL_REQUEST',
  GET_USER_EMAIL_SUCCESS: 'GET_USER_EMAIL_SUCCESS',
  GET_USER_EMAIL_FAILURE: 'GET_USER_EMAIL_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  RESET_PASSWORD_CONFIRM_REQUEST: 'RESET_PASSWORD_CONFIRM_REQUEST',
  RESET_PASSWORD_CONFIRM_SUCCESS: 'RESET_PASSWORD_CONFIRM_SUCCESS',
  RESET_PASSWORD_CONFIRM_FAILURE: 'RESET_PASSWORD_CONFIRM_FAILURE',

  AUTH_CLIENT_WITH_IDS_REQUEST: 'AUTH_CLIENT_WITH_IDS_REQUEST',
  AUTH_CLIENT_WITH_IDS_SUCCESS: 'AUTH_CLIENT_WITH_IDS_SUCCESS',
  AUTH_CLIENT_WITH_IDS_FAILURE: 'AUTH_CLIENT_WITH_IDS_FAILURE',
};
