'use client';

import { put, select, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { types as terytTypes } from '../teryt/types';
import { createSaga } from '@redux/sagas';
import { DefaultApiAction, RootState } from '@redux/interfaces';
import { toast } from 'react-toastify';
import { prepareTankDataObject } from '@utils/prepareTankDataObject';
import downloadPdf from '@utils/DownloadPdf';

export function* getClientPropertySaga() {
  yield takeLatest(
    types.GET_CLIENT_PROPERTY_REQUEST,
    createSaga('GET_CLIENT_PROPERTY', '/client/property/', 'GET')
  );
}

export function* getTankDetailsSaga() {
  yield takeLatest(
    types.GET_TANK_DETAILS_REQUEST,
    createSaga('GET_TANK_DETAILS', `/client/property`, 'GET')
  );
}

export function* updateTankLocalizationSaga() {
  yield takeLatest(
    types.UPDATE_TANK_LOCALIZATION_REQUEST,
    createSaga('UPDATE_TANK_LOCALIZATION', `/client/property`, 'PUT')
  );
}

export function* updateTankLocalizationSuccessSaga() {
  yield takeLatest(types.UPDATE_TANK_LOCALIZATION_SUCCESS, function* () {
    toast.success('Pomyślnie zapisano dane');
  });
}

export function* updateTankDataSaga() {
  yield takeLatest(
    types.UPDATE_TANK_DATA_REQUEST,
    createSaga('UPDATE_TANK_DATA', `/client/cesspool-data`, 'PUT')
  );
}

export function* updateTankDataSuccessSaga() {
  yield takeLatest(types.UPDATE_TANK_DATA_SUCCESS, function* () {
    toast.success('Pomyślnie zapisano dane');
    yield put({ type: terytTypes.RESET_TERYT_AFTER_ADD });
  });
}

export function* updateTankDataFailureSaga() {
  yield takeLatest(
    types.UPDATE_TANK_DATA_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const { body } = action.payload;

        switch (body.exception_name) {
          case 'ClientPropertyAddressMissingException':
            toast.error('Uzupełnij dane adresowe swojego zbiornika.');
            break;
        }
      }
    }
  );
}

export function* deleteCurrentTankSaga() {
  yield takeLatest(
    types.DELETE_TANK_REQUEST,
    createSaga('DELETE_TANK', `/client/property`, 'DELETE')
  );
}

export function* deleteCurrentTankSuccessSaga() {
  yield takeLatest(types.DELETE_TANK_SUCCESS, function* () {
    toast.success('Pomyślnie usunięto zbiornik');
    yield put({ type: types.GET_CLIENT_PROPERTY_REQUEST });
  });
}

export function* deleteCurrentTankFailureSaga() {
  yield takeLatest(types.DELETE_TANK_FAILURE, function* () {
    toast.error('Nie udało się usunąć zbiornika');
  });
}

export function* addNewTankSaga() {
  yield takeLatest(
    types.SET_NEW_TANK_LOCALIZATION_REQUEST,
    createSaga('SET_NEW_TANK_LOCALIZATION', `/client/property/`, 'POST')
  );
}

export function* addNewTankSuccessSaga() {
  yield takeLatest(
    types.SET_NEW_TANK_LOCALIZATION_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Zapisano nowy zbiornik');
      yield put({ type: types.GET_CLIENT_PROPERTY_REQUEST });
      yield put({ type: terytTypes.RESET_TERYT_AFTER_ADD });
    }
  );
}

export function* assignVirtualControlCodeSaga() {
  yield takeLatest(
    types.ASSIGN_VIRTUAL_CONTROL_CODE,
    createSaga(
      'ASSIGN_VIRTUAL_CONTROL_CODE',
      `/client/virtual-control/authcode`,
      'PUT'
    )
  );
}

export function* assignVirtualControlCodeSuccessSaga() {
  const getState = (state: RootState) => state.userCesspools;

  yield takeLatest(
    types.ASSIGN_VIRTUAL_CONTROL_CODE_SUCCESS,
    function* (action: DefaultApiAction) {
      const { currentTank, selectedTankId, tankDataValues } = yield select(
        getState
      );

      if (
        (Number(currentTank?.cesspoolfeatures?.cesspool_size) > 0 ||
          Number(currentTank?.ossffeatures?.cesspool_size) > 0) &&
        tankDataValues
      ) {
        yield put({
          type: types.UPDATE_TANK_DATA_REQUEST,
          data: prepareTankDataObject(tankDataValues),
          id: `${selectedTankId}/`,
        });
      }
      yield put({ type: types.GET_CLIENT_PROPERTY_REQUEST });
      toast.success('Udało się powiązać zbiornik');
    }
  );
}

export function* assignVirtualControlCodeFailureSaga() {
  yield takeLatest(
    types.ASSIGN_VIRTUAL_CONTROL_CODE_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się powiązać zbiornika');
    }
  );
}

export function* uploadSignedProtocolSaga() {
  yield takeLatest(
    types.UPLOAD_SIGNED_PROTOCOL_REQUEST,
    createSaga('UPLOAD_SIGNED_PROTOCOL', `/client/protocol`, 'POST')
  );
}

export function* uploadSignedProtocolSuccessSaga() {
  yield takeLatest(
    types.UPLOAD_SIGNED_PROTOCOL_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie wysłano protokół');
    }
  );
}

export function* uploadSignedProtocolFailureSaga() {
  yield takeLatest(
    types.UPLOAD_SIGNED_PROTOCOL_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const {
          body: { protocol },
        } = action.payload;

        if (protocol?.length >= 1) {
          switch (protocol[0]) {
            case 'does_not_contain_signature':
              toast.error('Nie udało się zweryfikować podpisu pliku.');
              break;
            case 'file_is_not_pdf':
              toast.error('Wysłany plik powinien być w formacie pdf.');
              break;
            case 'does_not_contain_2_signatures':
              toast.error(
                'Nie udało się zweryfikować podpisu pliku. Upewnij się, że protokół został przez Ciebie podpisany.'
              );
              break;
            default:
              toast.error('Nie udało się wgrać protokołu');
              break;
          }
        } else {
          toast.error('Wystąpił wewnętrzny problem.');
        }
      }
    }
  );
}

// Pobieranie ID/PDF

export function* getProtocolSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_ID_REQUEST,
    createSaga('GET_PROTOCOL_ID', `/client/protocol`, 'GET')
  );
}

export function* getProtocolFailureSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_ID_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Wystąpił problem z generowaniem protokołu');
    }
  );
}

export function* getProtocolSuccessSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_ID_SUCCESS,
    function* (action: DefaultApiAction) {
      if (!action.payload?.id) {
        const { file, name } = action.payload;
        downloadPdf(name, file);
        yield put({ type: types.RESET_PROTOCOL_ID });
      }
    }
  );
}

export function* getProtocolFileSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_FILE_REQUEST,
    createSaga('GET_PROTOCOL_FILE', `/client/protocol`, 'GET')
  );
}

export function* getProtocolFileSuccessSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_FILE_SUCCESS,
    function* (action: DefaultApiAction) {
      if (action.status === 200 && action.payload.status === 'FAILURE') {
        toast.error('Wystąpił problem podczas pobierania protokołu');
        yield put({ type: types.RESET_PROTOCOL_ID });
      } else if (action.status === 201) {
        const { file, name } = action.payload;
        downloadPdf(name, file);
        yield put({ type: types.RESET_PROTOCOL_ID });
      }
    }
  );
}

export function* getProtocolFileFailureSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_FILE_FAILURE,
    function* (action: DefaultApiAction) {
      yield put({ type: types.RESET_PROTOCOL_ID });
      toast.error('Wystąpił problem podczas pobierania protokołu');
    }
  );
}

// Pobieranie szczegółów protokołu
export function* getProtocolDetailsSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_DETAILS_REQUEST,
    createSaga('GET_PROTOCOL_DETAILS', `/client/protocol`, 'GET')
  );
}

export function* getProtocolDetailsFailureSaga() {
  yield takeLatest(
    types.GET_PROTOCOL_DETAILS_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Wystąpił problem z pobraniem szczegółów protokołu');
    }
  );
}

// Wysyłanie kontroli z komentarzem
export function* sendProtocolCommentSaga() {
  yield takeLatest(
    types.SEND_COMMENT_PROTOCOL_REQUEST,
    createSaga('SEND_COMMENT_PROTOCOL', `/client/protocol`, 'POST')
  );
}

export function* sendProtocolCommentFailureSaga() {
  yield takeLatest(
    types.SEND_COMMENT_PROTOCOL_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error(
        'Wystąpił problem z wysłaniem protokołu kontroli. Spróbuj ponownie później.'
      );
    }
  );
}

export function* sendProtocolCommentSuccessSaga() {
  yield takeLatest(
    types.SEND_COMMENT_PROTOCOL_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie wysłano kontrolę do urzędu');
    }
  );
}
