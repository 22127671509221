'use client';

export const types = {
  // GET /client/agreement/{id}/
  DOWNLOAD_AGREEMENT_REQUEST: 'DOWNLOAD_AGREEMENT_REQUEST',
  DOWNLOAD_AGREEMENT_SUCCESS: 'DOWNLOAD_AGREEMENT_SUCCESS',
  DOWNLOAD_AGREEMENT_FAILURE: 'DOWNLOAD_AGREEMENT_FAILURE',

  // GET /client/agreement
  GET_AGREEMENT_LIST_REQUEST: 'GET_AGREEMENT_LIST_REQUEST',
  GET_AGREEMENT_LIST_SUCCESS: 'GET_AGREEMENT_LIST_SUCCESS',

  // GET /client/agreement
  GET_SINGLE_AGREEMENT_REQUEST: 'GET_SINGLE_AGREEMENT_REQUEST',
  GET_SINGLE_AGREEMENT_SUCCESS: 'GET_SINGLE_AGREEMENT_SUCCESS',

  // POST /client/agreement
  ADD_NEW_AGREEMENT_REQUEST: 'ADD_NEW_AGREEMENT_REQUEST',
  ADD_NEW_AGREEMENT_SUCCESS: 'ADD_NEW_AGREEMENT_SUCCESS',
  ADD_NEW_AGREEMENT_FAILURE: 'ADD_NEW_AGREEMENT_FAILURE',
  RESET_ADD_AGREEMENT: 'RESET_ADD_AGREEMENT',

  // PUT /client/agreement/{id}
  EDIT_AGREEMENT_REQUEST: 'EDIT_AGREEMENT_REQUEST',
  EDIT_AGREEMENT_SUCCESS: 'EDIT_AGREEMENT_SUCCESS',
  EDIT_AGREEMENT_FAILURE: 'EDIT_AGREEMENT_FAILURE',

  // PUT /client/agreement/{id}
  DELETE_AGREEMENT_REQUEST: 'DELETE_AGREEMENT_REQUEST',
  DELETE_AGREEMENT_SUCCESS: 'DELETE_AGREEMENT_SUCCESS',
  DELETE_AGREEMENT_FAILURE: 'DELETE_AGREEMENT_FAILURE',

  // PUT /client/agreement/{id}/sign/
  SIGN_NEW_AGREEMENT_REQUEST: 'SIGN_NEW_AGREEMENT_REQUEST',
  SIGN_NEW_AGREEMENT_SUCCESS: 'SIGN_NEW_AGREEMENT_SUCCESS',
  SIGN_NEW_AGREEMENT_FAILURE: 'SIGN_NEW_AGREEMENT_FAILURE',
};
