'use client';

import { types } from './types';
import { toast } from 'react-toastify';
import {
  ConfirmEmailInterface,
  DataInterface,
} from '@forms/RegisterForm/interface';

export const loginAction = (data: {
  email: string;
  password: string;
  recaptcha?: string;
}) => {
  return {
    method: 'POST',
    type: types.LOGIN_REQUEST,
    data,
  };
};

export const savePinAction = (data: string) => {
  return {
    type: types.SAVE_PIN,
    data,
  };
};

export const logoutAction = () => {
  sessionStorage.removeItem('TOKEN');
  return {
    type: types.LOGOUT_USER,
  };
};

export const getUserId = () => {
  return {
    type: types.GET_USER_ID_REQUEST,
  };
};

export const setBackOffline = () => {
  return {
    type: types.SET_BACK_OFFLINE,
  };
};

export const setBackOnline = () => {
  return {
    type: types.SET_BACK_ONLINE,
  };
};

export const getDocuments = () => {
  return {
    method: 'GET',
    type: types.GET_DOCUMENTS_REQUEST,
  };
};

export const registerAction = (data: DataInterface) => {
  return {
    method: 'POST',
    type: types.CREATE_ACCOUNT_REQUEST,
    data,
  };
};

export const confirmEmail = (data: ConfirmEmailInterface) => {
  return {
    method: 'POST',
    type: types.CONFIRM_EMAIL_REQUEST,
    data,
  };
};

export const getEmail = (data: ConfirmEmailInterface) => {
  return {
    method: 'GET',
    type: types.GET_USER_EMAIL_REQUEST,
    data,
  };
};

export const resetPasswordAction = (data: { email: string }) => {
  return {
    method: 'POST',
    type: types.RESET_PASSWORD_REQUEST,
    data,
  };
};

export const resetPasswordConfirmAction = (data: {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}) => {
  return {
    method: 'POST',
    type: types.RESET_PASSWORD_CONFIRM_REQUEST,
    data,
  };
};

export const getCaptchaRequest = () => {
  return {
    type: types.GET_CAPTCHA_REQUEST,
  };
};
export const getCaptchaRequestFailure = () => {
  toast.error(
    'Aplikacja utraciła dostępu do internetu. Przywróć połączenie i spróbuj ponownie.'
  );

  return {
    type: types.GET_CAPTCHA_FAILURE,
  };
};

export const loginFromBoss = () => {
  return {
    type: types.LOGIN_FROM_BOSS,
  };
};

export const authClientWithIds = (id: string) => ({
  type: types.AUTH_CLIENT_WITH_IDS_REQUEST,
  id,
});
