import Router from 'next/router';
const CryptoJS = require('crypto-js');
import { toast } from 'react-toastify';
import { put, select, takeLatest } from 'redux-saga/effects';

import { types } from './types';
import { createSaga } from '@redux/sagas';
import { AuthApiResult, DefaultApiAction, RootState } from '@redux/interfaces';
import { globalNavigate } from '../../navigation/globalHistory';
import authService from '@services/authService';

export function* loginSaga() {
  yield takeLatest(
    types.LOGIN_REQUEST,
    createSaga('LOGIN', '/auth/login/', 'POST')
  );
}

export function* loginFailSaga() {
  yield takeLatest(types.LOGIN_FAILURE, function* (action: DefaultApiAction) {
    if (
      action?.payload?.body.exception_name === 'UserEmailNotVerifiedException'
    ) {
      globalNavigate('/first-verify', { replace: true });
    } else {
      toast.error('E-mail lub hasło są nieprawidłowe. Spróbuj ponownie');
    }
  });
}

export function* loginSuccessSaga() {
  const getState = (state: RootState) => state.auth;

  yield takeLatest(types.LOGIN_SUCCESS, function* (action: DefaultApiAction) {
    const items: AuthApiResult = yield select(getState);

    if (items.pin) {
      const token = action.payload;
      const secret = items.pin;

      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(token),
        secret
      ).toString();
      localStorage.setItem('encryptedToken', ciphertext);
      yield put({ type: types.DELETE_PIN });
    }
  });
}

export function* getUserIdSaga() {
  yield takeLatest(
    types.GET_USER_ID_REQUEST,
    createSaga('GET_USER_ID', '/auth/user/', 'GET')
  );
}

export function* getDocumentsSaga() {
  yield takeLatest(
    types.GET_DOCUMENTS_REQUEST,
    createSaga('GET_DOCUMENTS', '/documents/registration/', 'GET')
  );
}

export function* registerSaga() {
  yield takeLatest(
    types.CREATE_ACCOUNT_REQUEST,
    createSaga('CREATE_ACCOUNT', '/auth/registration/', 'POST')
  );
}

export function* registerSuccessSaga() {
  yield takeLatest(
    types.CREATE_ACCOUNT_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Udało się utworzyć konto 🥳');
    }
  );
}

export function* registerFailSaga() {
  yield takeLatest(
    types.CREATE_ACCOUNT_FAILURE,
    function* (action: DefaultApiAction) {
      const { body } = action.payload;

      switch (body?.exception_name) {
        case 'UserEmailAlreadyExistException':
          toast.error('Istnieje już użytkownik z takim adresem email.');
          break;
        case 'WrongInvitationNumberException':
          toast.error('Upewnij się, że kod autoryzujący gminy jest poprawny.');
          break;
        case 'InvitationNumberIsUsedAlreadyException':
          toast.error(
            'Ten kod autoryzujący został już przypisany do innego konta.'
          );
          break;
        default:
          toast.error(body?.detail ? body.detail : 'Wystąpił nieznany błąd');
      }
    }
  );
}

export function* confirmEmail() {
  yield takeLatest(
    types.CONFIRM_EMAIL_REQUEST,
    createSaga('CONFIRM_EMAIL', '/auth/registration/verify-email/', 'POST')
  );
}

export function* confirmEmailSuccess() {
  yield takeLatest(types.CONFIRM_EMAIL_SUCCESS, function* () {
    Router.push('/');
    toast.success('Udało się zweryfikować adres email.');
  });
}

export function* getEmailSaga() {
  yield takeLatest(
    types.GET_USER_EMAIL_REQUEST,
    createSaga('GET_USER_EMAIL', '/auth/registration/verify-email/', 'GET')
  );
}

export function* getEmailSuccessSaga() {
  yield takeLatest(types.GET_USER_EMAIL_SUCCESS, function* () {});
}

export function* getEmailFailSaga() {
  yield takeLatest(types.GET_USER_EMAIL_FAILURE, function* () {
    toast.error('Nie udało się pobrać adresu email.');
  });
}

export function* resetPasswordSaga() {
  yield takeLatest(
    types.RESET_PASSWORD_REQUEST,
    createSaga('RESET_PASSWORD', '/auth/password/reset/', 'POST')
  );
}

export function* resetPasswordSuccessSaga() {
  yield takeLatest(types.RESET_PASSWORD_SUCCESS, function* () {
    globalNavigate('/forgotten-password/done', { replace: true });
  });
}
export function* resetPasswordFailSaga() {
  yield takeLatest(types.RESET_PASSWORD_FAILURE, function* () {
    toast.error('Nie udało się zresetować hasła');
  });
}

export function* resetPasswordConfirmSaga() {
  yield takeLatest(
    types.RESET_PASSWORD_CONFIRM_REQUEST,
    createSaga(
      'RESET_PASSWORD_CONFIRM',
      '/auth/password/reset/confirm/',
      'POST'
    )
  );
}

export function* resetPasswordConfirmSuccessSaga() {
  yield takeLatest(types.RESET_PASSWORD_CONFIRM_SUCCESS, function* () {
    Router.push('/');
    toast.success('Hasło pomyślnie zresetowane');
  });
}
export function* resetPasswordConfirmFailSaga() {
  yield takeLatest(types.RESET_PASSWORD_CONFIRM_FAILURE, function* () {
    toast.error('Nie udało się zresetować hasła');
  });
}

export function* authClientWithIdsSaga() {
  yield takeLatest(
    types.AUTH_CLIENT_WITH_IDS_REQUEST,
    createSaga('AUTH_CLIENT_WITH_IDS', '/client/login-token', 'GET')
  );
}

export function* authClientWithIdsSuccessSaga() {
  yield takeLatest(
    types.AUTH_CLIENT_WITH_IDS_SUCCESS,
    function* (action: DefaultApiAction) {
      authService.setToken(action.payload.login_token);
      Router.push('/');
      toast.success('Udało się zalogować');
    }
  );
}
export function* authClientWithIdsFailureSaga() {
  yield takeLatest(
    types.AUTH_CLIENT_WITH_IDS_FAILURE,
    function* (action: DefaultApiAction) {
      console.log('action data: ', action.payload);

      toast.error('Wystąpił błąd podczas próby zalogowania.');
    }
  );
}
