'use client';

import { AgreementsInterface } from '@redux/interfaces';
import { types } from '@redux/agreements/types';

export const INIT_STATE: AgreementsInterface = {
  loading: false,
  error: false,
  agreements: [],
  agreementAddedOrEdited: false,
};

export default function reducer(
  state: AgreementsInterface = INIT_STATE,
  action: any
): AgreementsInterface {
  switch (action.type) {
    case types.DOWNLOAD_AGREEMENT_REQUEST:
    case types.DELETE_AGREEMENT_REQUEST:
    case types.EDIT_AGREEMENT_REQUEST:
    case types.GET_AGREEMENT_LIST_REQUEST:
    case types.ADD_NEW_AGREEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.DOWNLOAD_AGREEMENT_SUCCESS:
    case types.DELETE_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case types.DOWNLOAD_AGREEMENT_FAILURE:
    case types.DELETE_AGREEMENT_FAILURE:
    case types.EDIT_AGREEMENT_FAILURE:
    case types.ADD_NEW_AGREEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.ADD_NEW_AGREEMENT_SUCCESS:
    case types.EDIT_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        agreementAddedOrEdited: true,
      };
    case types.RESET_ADD_AGREEMENT:
      return {
        ...state,
        agreementAddedOrEdited: false,
      };
    case types.GET_AGREEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        agreements: action.payload,
      };
    case types.SIGN_NEW_AGREEMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        agreementAddedOrEdited: false,
      };
    case types.SIGN_NEW_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        agreementAddedOrEdited: true,
      };
    case types.SIGN_NEW_AGREEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        agreementAddedOrEdited: false,
      };
    default:
      return state;
  }
}
